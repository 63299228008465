.footer-text {
  font-size: 1em;
}
@media (max-width: 400px) {
  .footer-text {
    font-size: 0.8em;
  }
}
@media (max-width: 300px) {
  .footer-text {
    font-size: 0.7em;
  }
}
