.CellWithComment {
  position: relative;
}
.CellComment {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: red;
  padding: 3px;
  color: red;
  box-shadow: 2px 3px 6px 0px #b0b0b0;
  border-radius: 5px;
  border: 1px solid #494949;
}
.CellWithComment:hover span.CellComment {
  display: block;
}
table {
  border-collapse: collapse;
  width: 100%;
  border: 2px solid gray;
  box-shadow: 2px 3px 6px 0px #b0b0b0;
}
@media print {
  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid gray;
    box-shadow: none;
    break-inside: avoid;
  }
}
td,
th {
  border: 1px solid lightgray;
}
.highlight {
  background-color: rgba(0, 0, 255, 0.15);
}
th {
  text-align: center;
  background-color: #f0fff0;
  color: #003000;
}
@media print {
  th,
  td {
    font-weight: normal;
    background-color: white;
    color: black;
  }
}
tr:nth-child(even) {
  background-color: #fafafa;
}
tr:nth-child(odd) {
  background-color: white;
}
tr:hover {
  background-color: #ddd;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.text-gray {
  color: gray;
}
.fat-border-right {
  border-right: 2px solid gray;
}
@media print {
  .fat-border-right {
    border-right: 1px solid lightgray;
  }
}
.fat-border-bottom {
  border-bottom: 2px solid gray;
}
@media print {
  .fat-border-bottom {
    border-bottom: 1px solid gray;
  }
}
.fat-border {
  border: 2px solid gray;
}
@media print {
  .fat-border {
    border: 1px solid gray;
  }
}
.braces {
  position: relative;
  padding: 0.3em 0.5em;
  margin-left: 1em;
}
.braces::before,
.braces::after,
.curly::before,
.curly::after {
  content: "";
  position: absolute;
  width: 0.8em;
  height: 25%;
}
.braces::before,
.braces::after {
  left: -1.6em;
  border-right: 2px solid;
}
.braces::before {
  top: 0;
  border-top-right-radius: 0.8em;
}
.braces::after {
  bottom: 0;
  border-bottom-right-radius: 0.8em;
}
.curly::before,
.curly::after {
  left: -0.8em;
  border-left: 2px solid;
}
.curly::before {
  top: 25%;
  border-bottom-left-radius: 0.8em;
}
.curly::after {
  bottom: 25%;
  border-top-left-radius: 0.8em;
}
