.sortable-table th {
  position: relative;
  padding: 8px 24px 8px 8px;
  background-color: #f0fff0;
  transition: all 0.2s ease;
}
.sortable-table th:hover {
  background-color: #e0ffe0;
  box-shadow: inset 0 0 0 1px #ccc;
}
.sortable-table th span {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8em;
  color: #666;
  transition: transform 0.2s ease;
}
.sortable-table th span:hover {
  transform: translateY(-50%) scale(1.2);
}
.sortable-table tbody tr {
  transition: background-color 0.2s ease;
}
.sortable-table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
/* Main button style */
main button {
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: all 0.2s ease;
  background: #778abb;
  color: white;
  box-shadow: 2px 3px 6px 0px #b0b0b0;
  border: 1px solid #bfbfbf;
  padding: 4px;
  margin: 4px;
}
main button:hover {
  background: #667aab;
  box-shadow: 3px 4px 7px 0px #9b9b9b;
}
main button:active:not(:disabled) {
  background: #5e6c98;
  box-shadow: 1px 2px 4px 0px #8c8c8c;
  transform: translateY(2px);
}
main button:disabled {
  background: #b0b0b0;
  color: #f0f0f0;
  border: 1px solid #d1d1d1;
  box-shadow: none;
  cursor: not-allowed;
  transform: none;
}
input {
  background: #aab3d2;
  color: white;
  box-shadow: 2px 3px 6px 0px #b0b0b0;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  font-size: 1em;
  outline: #29447a;
  transition: 0.3s ease;
  margin: 4px;
  padding: 4px;
}
input:focus {
  background: #c0c7de;
  border: 1px solid #5A7EC7;
  color: #325192;
  border-radius: 5px;
}
input::placeholder {
  color: #DDDDDD;
}
.div-as-input {
  background: #aab3d2;
  color: white;
  box-shadow: 2px 3px 6px 0px #b0b0b0;
  border-radius: 5px;
  border: 1px solid #bfbfbf;
  font-size: 1em;
  outline: #29447a;
  transition: 0.3s ease;
  margin: 4px;
  padding: 4px;
}
.div-as-input:focus {
  background: #c0c7de;
  border: 1px solid #5A7EC7;
  color: #325192;
  border-radius: 5px;
}
.div-as-input::placeholder {
  color: #DDDDDD;
}
body {
  font-family: Arial, sans-serif;
  font-size: 1em;
  background: #ebebfa;
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
}
header,
footer {
  background: #778abb;
  background-size: 200% 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.login-menu-content {
  background: #778abb;
  background-size: 200% 100%;
  color: #fff;
}
footer {
  bottom: 0;
  padding: 5px;
}
@keyframes gradientAnimation {
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: -100% 50%;
  }
}
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex: 1;
  padding: 1rem;
  overflow-y: hidden;
  background-color: transparent;
  margin-bottom: 40px;
}
main .logo-container {
  margin-top: 60px;
  margin-bottom: 40px;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
main .logo-img {
  max-width: 100%;
  height: auto;
}
main .logo-text {
  margin-top: 20px;
  background-image: linear-gradient(90deg, #896b39, #d4cfa3, #896b39);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: 'Monotype Corsiva', 'Apple Chancery', 'ITC Zapf Chancery', 'URW Chancery L', cursive;
  font-size: 50px;
  background-size: 200% 100%;
  animation: gradientAnimation 5s linear infinite alternate;
  padding: 10px;
}
*,
::after,
::before {
  box-sizing: border-box;
}
@media (max-width: 400px) {
  h1 {
    font-size: 1.5em;
  }
}
@media (max-width: 300px) {
  h1 {
    font-size: 1.2em;
  }
}
@media print {
  header,
  footer,
  button,
  input {
    display: none;
  }
  main {
    display: block;
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .new-page {
    /* Start a new page before this element */
    page-break-before: always;
  }
  canvas {
    filter: grayscale(80%) contrast(120%);
  }
}
