.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.modal-header h2 {
  margin: 0;
  color: #000;
}
.modal-header .modal-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;
  color: #666;
}
.modal-header .modal-close-button:hover {
  color: #000;
}
.modal-form-description {
  margin-bottom: 20px;
  color: #666;
}
.modal-form-input-container {
  margin-bottom: 15px;
}
.modal-form-input-container input {
  width: 100%;
}
.modal-form-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}
.modal-form-message.success {
  background-color: #e6ffe6;
  color: #006600;
}
.modal-form-message.error {
  background-color: #ffe6e6;
  color: #cc0000;
}
.modal-form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
