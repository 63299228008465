.horiz-container {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.vert-container {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
#matrix-canvas {
  width: 100%;
}
.validation-message {
  display: none;
  position: absolute;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 0px;
  z-index: 1;
  white-space: normal;
  background-color: #ffe3e3;
  border-style: solid;
  border-width: 2px;
  border-color: #ff0000;
  width: 175px;
  color: #ff0000;
  text-align: center;
}
.validation-message.i-error {
  display: block;
}
input.i-error {
  border-color: red;
}
.hint-box {
  /* display: none; */
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: red;
  padding: 3px;
  color: red;
  box-shadow: 2px 3px 6px 0px #b0b0b0;
  border-radius: 5px;
  border: 1px solid #494949;
}
.space-right {
  margin: 0 8px 0 0;
}
.space-left {
  margin: 0 0 0 8px;
}
.space-right-down {
  margin: 4px;
}
.space-down {
  margin: 0 0 8px 0;
}
.justify-space-between {
  justify-content: space-between;
}
.frame-first {
  background: rgba(106, 106, 255, 0.1);
  border: 2px solid #6a6aff;
  border-radius: 3px;
  box-shadow: 2px 3px 6px 0px #b0b0b0;
  margin: 0 0 8px 0;
  width: 100%;
}
@media print {
  .frame-first {
    background: white;
    border: 0.1px solid gray;
  }
}
.frame-second {
  background: rgba(255, 117, 117, 0.1);
  border: 2px solid #ff7575;
  border-radius: 2px;
  box-shadow: 2px 3px 6px 0px #b0b0b0;
  width: 100%;
}
@media print {
  .frame-second {
    background: white;
    border: 0.1px solid gray;
  }
}
