.login-menu {
  position: relative;
}
.login-menu-content {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
}
.login-menu-content .button-login {
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: all 0.2s ease;
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
  background-color: #4e639c;
  color: white;
}
.login-menu-content .button-login:hover {
  background-color: #3b4c75;
}
.login-menu-content .button-clear-cache {
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: all 0.2s ease;
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
  background-color: #ff6b6b;
  color: white;
}
.login-menu-content .button-clear-cache:hover {
  background-color: #ff5252;
}
.password-input-container {
  display: flex;
  flex-direction: column;
}
.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.input-password {
  width: 100%;
  padding-right: 30px;
}
.toggle-password-button {
  position: absolute;
  right: -5px;
  cursor: pointer;
  border: none;
  background: none;
}
