.header-text {
  font-size: 1em;
}
@media (max-width: 400px) {
  .header-text {
    font-size: 0.8em;
  }
}
@media (max-width: 300px) {
  .header-text {
    font-size: 0.7em;
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}
header .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #778abb;
  width: 100%;
}
header .update-banner {
  width: 100%;
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
header .update-banner .banner-buttons {
  display: flex;
  gap: 10px;
}
header .update-banner button {
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: all 0.2s ease;
  background: white;
  padding: 5px 15px;
  font-weight: bold;
}
header .update-banner button:hover {
  background: #e8e8e8;
}
header .update-banner button.dismiss-button {
  background: transparent;
  color: white;
  border: 1px solid white;
}
header .update-banner button.dismiss-button:hover {
  background: rgba(255, 255, 255, 0.1);
}
header .update-banner button.update-button {
  color: #4caf50;
}
header .menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
header .menu a {
  color: white;
  text-decoration: none;
  margin: 0 2px;
}
header .language-selector select {
  background: none;
  border: none;
  color: white;
  font-size: 1em;
}
header .language-selector option {
  background-color: #778abb;
  color: white;
}
header button {
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: all 0.2s ease;
  background: none;
  color: white;
  padding: 1px 5px;
}
header button:active {
  transform: scale(0.8);
}
.svg-icon {
  width: 22px;
}
@media (max-width: 400px) {
  .svg-icon {
    font-size: 0.8em;
  }
}
@media (max-width: 300px) {
  .svg-icon {
    font-size: 0.7em;
  }
}
main {
  transition: margin-top 0.3s ease;
  margin-top: 40px;
}
header:has(.update-banner) ~ main {
  margin-top: 80px;
}
